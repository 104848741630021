var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-breadcrumbs',{staticClass:"grey lighten-2 white--text",attrs:{"items":_vm.breadcrumbs}}),(_vm.loading)?_c('v-progress-circular',{staticClass:"d-flex mx-auto py-8 mt-8",attrs:{"size":100,"width":4,"color":"fmq_black","indeterminate":""}}):_c('div',[_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-4"},[_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","dark":"","outlined":"","to":"/banco-de-cartas-tr"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v("Voltar")],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('ModelosFormularioTR',{attrs:{"artigos":_vm.artigosSelecionados,"listTags":_vm.listTags,"listCategorias":_vm.listCategorias,"listArtigos":_vm.listArtigos,"listProdutos":_vm.listProdutos,"modelo":_vm.modelo,"loadingBtn":_vm.loadingBtn},on:{"send":function($event){return _vm.formatPayload($event)},"buscarArtigo":function($event){_vm.dialogArtigo = true;
              _vm.buscarArtigo(_vm.formDataArtigo);},"excluirArtigo":_vm.excluirArtigo}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('ModelosLogsEditar',{attrs:{"logs":_vm.modelo.logs}})],1)],1)],1)]),_c('AlertSuccess',{attrs:{"dialog":_vm.success,"dialogMessage":"Modelo editado com sucesso"},on:{"close":_vm.goTo}}),_c('AlertError',{attrs:{"alertError":_vm.error,"messageError":_vm.messageError}}),_c('TableModal',{attrs:{"dialog":_vm.dialogArtigo,"dialogMessage":"Lista de Artigos"},on:{"close":function($event){_vm.dialogArtigo = false}}},[_c('ArtigosTabelaBuscar',{attrs:{"headers":_vm.headersArtigos,"itens":_vm.artigos,"total":_vm.total,"pageText":_vm.pageText,"pageNumber":_vm.page,"loading":_vm.loadingArtigos},on:{"handleFilter":_vm.filterArtigo,"selecionar":_vm.selecionarArtigo},scopedSlots:_vm._u([{key:"produtos",fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listProdutos,"hide-no-data":"","hide-selected":"","item-text":"nome","item-value":"id","placeholder":"Busque pelo produto","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscarArtigo(_vm.formDataArtigo);}},model:{value:(_vm.formDataArtigo.produtosId),callback:function ($$v) {_vm.$set(_vm.formDataArtigo, "produtosId", $$v)},expression:"formDataArtigo.produtosId"}})]},proxy:true},{key:"autor",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Busque pelo autor","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscarArtigo(_vm.formDataArtigo);}},model:{value:(_vm.formDataArtigo.autor),callback:function ($$v) {_vm.$set(_vm.formDataArtigo, "autor", $$v)},expression:"formDataArtigo.autor"}})]},proxy:true},{key:"dataPublicacao",fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listDatas,"hide-no-data":"","hide-selected":"","item-text":"value","item-value":"value","placeholder":"Busque pelo ano","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscarArtigo(_vm.formDataArtigo);}},model:{value:(_vm.formDataArtigo.dataPublicacao),callback:function ($$v) {_vm.$set(_vm.formDataArtigo, "dataPublicacao", $$v)},expression:"formDataArtigo.dataPublicacao"}})]},proxy:true},{key:"categorias",fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listCategorias,"hide-no-data":"","hide-selected":"","item-text":"nome","item-value":"id","placeholder":"Busque pela categoria","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscarArtigo(_vm.formDataArtigo);}},model:{value:(_vm.formDataArtigo.categoriasId),callback:function ($$v) {_vm.$set(_vm.formDataArtigo, "categoriasId", $$v)},expression:"formDataArtigo.categoriasId"}})]},proxy:true},{key:"titulo",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Busque pelo titulo","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscarArtigo(_vm.formDataArtigo);}},model:{value:(_vm.formDataArtigo.titulo),callback:function ($$v) {_vm.$set(_vm.formDataArtigo, "titulo", $$v)},expression:"formDataArtigo.titulo"}})]},proxy:true},{key:"tags",fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listTags,"hide-no-data":"","hide-selected":"","item-text":"nome","item-value":"id","placeholder":"Busque pela tag","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscarArtigo(_vm.formDataArtigo);}},model:{value:(_vm.formDataArtigo.tagsId),callback:function ($$v) {_vm.$set(_vm.formDataArtigo, "tagsId", $$v)},expression:"formDataArtigo.tagsId"}})]},proxy:true},{key:"link",fn:function(){return [_c('v-text-field',{attrs:{"solo-inverted":"","flat":"","dense":"","hide-details":true,"disabled":"","readonly":""}})]},proxy:true},{key:"id",fn:function(){return [_c('div',{staticClass:"d-flex justify-start"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","x-small":"","fab":"","color":"red"},on:{"click":_vm.clearArtigo}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"outlined":"","x-small":"","fab":"","color":"blue"},on:{"click":_vm.searchArtigo}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)]},proxy:true}])})],1),_c('ModelosAtulizarModal',{attrs:{"dialog":_vm.modeloUpdate},on:{"close":function($event){_vm.modeloUpdate = false},"update":function($event){return _vm.editarMovito($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }